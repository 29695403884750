import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      Functionality is under maintenance. <br /> Sorry for the inconvenience...
    </div>
  );
}

export default App;
